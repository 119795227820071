<template>
  <CRow>
    <Modal :modal="modal"></Modal>
    <CCol lg="12">
      <div class="d-flex align-items-center justify-content-end mb-3">
        <a href="javascript:void(0)" @click="$router.go(-1)">
          <CIcon name="cil-arrow-left" class="mr-2" />Voltar
        </a>
      </div>
      <CForm @submit.prevent="send">
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">
              {{ $route.params.id ? 'Editar Notí­cia' : 'Cadastrar Notí­cia' }}
            </h4>
            <div class="d-flex align-items-center">
              <div class="ml-2 d-flex align-items-center">
                <label class="mb-0">
                  <b>Status</b>
                </label>
                <CSwitch
                  type="checkbox"
                  color="success"
                  variant="3d"
                  class="mx-2"
                  v-bind="statusIcon"
                  :checked.sync="form.status"
                />
              </div>
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="12" md="12" sm="12">
                <div class="form-group">
                  <label for="category_id">Categoria*</label>
                  <Multiselect
                    id="category_id"
                    v-model="form.category_id"
                    :options="categoriesOptions"
                    :disabled="processing"
                    :required="true"
                  >
                    <span slot="noResult">Nenhum resultado encontrado</span>
                  </Multiselect>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="3">
                <label for="focus-of-action">Data da publicação*</label>
                <div class="form-group">
                  <Datepicker
                    :value="form.publication_at"
                    class="no-margin-bottom"
                    required
                    :disabled="processing"
                    @selected="
                      (publication_at) =>
                        (form.publication_at = form.publication_at =
                          publication_at)
                    "
                  />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  id="first"
                  v-model="form.title"
                  label="Título*"
                  placeholder="Título"
                  required
                  :disabled="processing"
                />
              </CCol>
              <CCol sm="6">
                <FriendlyUrl
                  :value="form.slug"
                  :based-on="form.title"
                  :entity="$route.params.id"
                  field="slug"
                  model="news"
                  @change="(value) => (form.slug = value)"
                />
              </CCol>
              <CCol sm="12">
                <div class="form-group">
                  <label for="focus-of-action">Texto*</label>
                  <TextEditor
                    v-model="form.text"
                    :disabled="processing"
                    type="textWithOutImage"
                  />
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h5 class="mb-0">Imagem Principal</h5>
          </CCardHeader>
          <CCardBody>
            <div class="form-group mb-0">
              <FileUploader
                id="main_image"
                :max-files="1"
                :uploader-info="mainImageConfig.uploaderInfo"
                :extensions="mainImageConfig.extensions"
                :accept-types="mainImageConfig.acceptTypes"
                :enable-multiple="false"
                :medias="form.main_image"
                :min-cropped-width="930"
                :min-cropped-height="525"
                :enable-edit-on-api-table="false"
                sizeFile="5242880"
              />
            </div>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h5 class="mb-0">Galeria de Imagens</h5>
          </CCardHeader>
          <CCardBody>
            <div class="form-group mb-0">
              <FileUploader
                id="medias"
                :max-files="10"
                :uploader-info="mediasConfig.uploaderInfo"
                :extensions="mediasConfig.extensions"
                :accept-types="mediasConfig.acceptTypes"
                :enable-multiple="true"
                :medias="form.medias"
                :min-cropped-width="930"
                :min-cropped-height="525"
                sizeFile="5242880"
              />
            </div>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h5 class="mb-0">SEO</h5>
          </CCardHeader>
          <CCardBody>
            <Seo
              :form="form"
              :page-title="form.seo_title"
              :page-description="form.seo_description"
              :is-edit="!!$route.params.id"
            />
          </CCardBody>
        </CCard>
        <CRow v-if="$route.params.id">
          <CCol sm="12">
            <CardLog :log="log" />
          </CCol>
        </CRow>
        <div class="d-flex justify-content-end align-items-center mt-4 mb-4">
          <a
            v-if="$route.params.id"
            href="javascript:void(0)"
            class="mr-3"
            @click="resetForm"
            >Limpar Alterações</a
          >
          <CInputCheckbox
            v-else
            label="Criar outro"
            class="mr-3"
            :disabled="processing"
            @update:checked="setRedirect"
          />
          <CButton color="success" type="submit" :disabled="processing">
            {{ processing ? 'Salvando...' : 'Salvar' }}
          </CButton>
        </div>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import CardLog from '@/components/ui/CardLog'
import Datepicker from '@/components/ui/Datepicker'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import Service from '@/services/news.service'
import FriendlyUrl from '@/components/ui/FriendlyUrl'
import TextEditor from '@/components/ui/TextEditor'
import FileUploader from '@/components/ui/Fileuploader'
import Modal from '@/components/ui/Modal'
import Seo from '@/components/ui/Seo'
import CategoryService from '@/services/news-category.service'
import Multiselect from '@/components/ui/Multiselect'

export default {
  metaInfo: {
    title: 'Notí­cias',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  components: {
    CardLog,
    FriendlyUrl,
    TextEditor,
    Datepicker,
    FileUploader,
    Seo,
    Modal,
    Multiselect
  },

  data() {
    return {
      categories: [],
      itens: {
        category_id: '',
        title: '',
        slug: '',
        publication_at: '',
        favorite: false,
        status: true,
        text: '',
        main_image: [],
        medias: [],
        seo_title: '',
        seo_description: ''
      },
      form: {},
      mainImageConfig: {
        uploaderInfo: {
          label: 'Imagem'
        },
        extensions: 'jpg,jpeg,png',
        acceptTypes: 'image/png,image/jpeg'
      },
      mediasConfig: {
        uploaderInfo: {
          label: 'Imagens'
        },
        extensions: 'jpg,jpeg,png',
        acceptTypes: 'image/png,image/jpeg'
      },
      seo: {
        fields: {
          title: '',
          description: ''
        }
      },
      statusIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      },
      favoriteIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      },
      log: {},
      isLoading: true,
      processing: false,
      redirect: true,
      modal: {
        show: false,
        title: '',
        message: '',
        color: ''
      },
      categoriesOptions: [],
      selectedCategories: []
    }
  },

  async created() {
    await this.getCategoriesOptions()
    await this.setItens()

    if (this.$route.params.id) {
      const selected = this.categoriesOptions.find(
        (categoryItem) => categoryItem.value === this.form.category_id
      )

      this.selectedCategories = selected
      this.form.category_id = selected
    }
  },

  methods: {
    async getCategoriesOptions() {
      const response = await CategoryService.index()

      this.categories = response.data

      const categoriesStored = response.data.map((category) => {
        return {
          label: category.title,
          value: category.id
        }
      })

      this.categoriesOptions = [...categoriesStored]
    },

    async setItens() {
      if (this.$route.params.id) {
        const data = await Service.show(this.$route.params.id)

        if (data) {
          this.itens = {
            ...data,
            seo_title: data.seo && data.seo.title ? data.seo.title : '',
            seo_description:
              data.seo && data.seo.description ? data.seo.description : ''
          }

          this.log = {
            created_by: data.created ? data.created.name : 'Seed',
            created_at: moment(data.created_at).format('DD/MM/YYYY HH:mm'),
            updated_by: data.updated ? data.updated.name : null,
            updated_at: moment(data.updated_at).format('DD/MM/YYYY HH:mm')
          }
        }
      } else {
        this.itens.publication_at = moment().format('YYYY-MM-DD')
      }
      this.form = cloneDeep(this.itens)
    },

    resetForm() {
      this.setItens()
    },

    setRedirect(event) {
      this.redirect = !event
    },

    async send() {
      this.processing = true

      let response
      let message

      const data = {
        ...this.form,
        main_image: this.form.main_image.map((media) => media.id),
        medias: this.form.medias.map((media) => media.id),
        id: this.$route.params.id,
        category_id: this.form.category_id.value || null
      }

      if (this.$route.params.id) {
        response = await Service.update(this.$route.params.id, data)
        message = 'Item foi atualizada com sucesso!'
      } else {
        response = await Service.store(data)
        message = 'Item foi cadastrada com sucesso!'
      }

      this.processing = false

      if (response.status === 200) {
        this.modal = {
          show: true,
          title: 'Sucesso',
          message: message,
          color: 'success',
          redirect: this.redirect
        }

        if (this.redirect) {
          this.resetForm()
          setTimeout(() => {
            this.$router.push(
              this.$route.matched[this.$route.matched.length - 2].path
            )
          }, 3000)
        } else {
          this.resetForm()
          setTimeout(() => {
            this.processing = false
            document.getElementById('first').focus()
            this.modal.show = false
          }, 1000)
        }
      } else if (response.status === 400) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: `${
            response.data.length > 0
              ? response.data[0].message
              : 'Preencha todos os campos corretamente!'
          }`,
          color: 'danger'
        }
      } else {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Ocorreu um erro interno, por favor tente novamente!',
          color: 'danger'
        }
      }
    }
  },

  computed: {
    categoriesFilter: function () {
      // eslint-disable-next-line no-undef
      const categoriesStored = response.data.map((category) => {
        return {
          label: category.title,
          value: category.id
        }
      })

      return categoriesStored
    }
  }
}
</script>
